
import { defineComponent, ref, reactive, computed } from "vue";

import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";

import Empty from "@/components/common/Empty.vue";
import qs from "qs";
import router from "@/router";

export default defineComponent({
  name: "ApplyInvoice",
  components: {
    Empty,
  },

  setup() {
    let checkedIdList =ref(null);
    const type = ref("2");
    const disable = ref(false);
    let checkedIds = sessionStorage.getItem("fapiaoBid");
    if(checkedIds){
      checkedIdList = eval(checkedIds);
    }
    function onSubmit() {
      // let checkedIds = sessionStorage.getItem("fapiaoBid");
      const openInvoiceType = sessionStorage.getItem("openInvoiceType");
      if (checkedIds) {
        checkedIds = JSON.parse(checkedIds).join(",");
      }

      if (!openInvoiceType) {
        Toast({
          type: "warning",
          title: "开票类型错误，请重新选择开票类型！",
        });

        return false;
      }
      // console.log("taxrate111111",taxrate.value);
      axios
        .post(
          "/M/Server/DoSaveInvoiceApply",
          qs.stringify({
            checkedIds,
            openInvoiceType,
          }),
          {
            params: {
              validate: true,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });

            disable.value = true;
            localStorage.setItem("showRedPackage", 'true');
            router.push({
              name:'Invoice',
              query:{
                type:1
              }
            })
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: err.msg || err,
          });
        });
    }

    return {
      type,
      onSubmit,
      checkedIdList,
      disable,
    };
  },
});
